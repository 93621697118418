import {UserEventRegistration} from "../../../types/ClubEvent";
import useTranslation from "../../../hooks/contexts/TranslationContext";
import {useLocation} from "react-router-dom";
import axios from "axios";
import {postObjectWithResponse} from "../../../functions/objectService";
import {EntityPaymentRequest} from "../../../types/Transaction";
import {Card, CardContent} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import {dateTimeString} from "../../../utils/date";
import Button from "@mui/joy/Button";
import DownloadIcon from "@mui/icons-material/Download";
import PaymentButton from "../../form/PaymentButton";

export default function TicketCard({reg}: { reg: UserEventRegistration }) {
    const {t} = useTranslation()
    const location = useLocation()

    //const attachmentUrl = `${axios.defaults.baseURL}/Organisations/Events/Get/${reg.ticket.id}/Tickets/Attachment`
    const previewUrl = `${axios.defaults.baseURL}/Organisations/Events/Get/${reg.ticket.id}/Tickets/Attachment/Preview`

    async function settlePayment() {
        const response = await postObjectWithResponse<EntityPaymentRequest, string>(`/Organisations/${reg.orgId}/Payments/Ticket`, {
            successUri: `${window.location.origin}${location.pathname}?ticketName=${reg.eventTitle}&paid=1`,
            failedUri: `${window.location.origin}${location.pathname}?ticketName=${reg.eventTitle}&error=`,
            entityId: reg.ticket.id,
            eventId: reg.eventId as string
        })
        return response.data
    }

    return <Card sx={{width: "100%"}}>
        <div>
            <Typography level="title-lg">{reg.eventTitle}</Typography>
            <Typography
                level="body-sm">{`${t("Created on")} ${dateTimeString(reg.ticket.createdOn)}`}</Typography>
        </div>
        <CardContent orientation="horizontal">
            <div>
                <Typography level="body-xs">{t("Payment status")}:</Typography>
                <Typography fontSize="lg" fontWeight="lg" color={reg.ticket.paid ? "success" : "danger"}>
                    {t(reg.ticket.paid ? "Paid" : "Pending")}
                </Typography>
            </div>
            {reg.ticket.paid && reg.ticket.attachment !== null && <Button
                variant="plain"
                size="md"
                color="primary"
                sx={{ml: 'auto', alignSelf: 'center', fontWeight: 600}}
                onClick={() => window.open(previewUrl)}
            >
                {t("Download")} <DownloadIcon sx={{ml: 1}}/>
            </Button>}
            {!reg.ticket.paid &&
                <PaymentButton onClick={settlePayment} sx={{ml: 'auto', alignSelf: 'center', fontWeight: 600}}/>}
        </CardContent>
    </Card>
}
